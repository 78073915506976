import React, { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Accordion = ({
    open,
    onToggle,
    title,
    html,
    text = null,
    scrollOnClick = false,
    wrapperRef,
}) => {
    const $box = useRef(null);
    return (
        <div
            tabIndex={0}
            className={`${styles.accordion} ${open ? styles.accordionOpen : ''}`}
            ref={$box}
            onClick={() => {
                onToggle(!open);
                // scrollOnClick && !open && $box?.current?.scrollIntoView({ behavior: 'smooth' });
            }}
        >
            <div className={styles.accordionTitle}>
                <span>
                    <span>{title}</span>
                    <img src="/images/squiggle.svg" alt="a squiggle line" />
                </span>

                <div className={styles.accordionTitleIcon}>
                    <FontAwesomeIcon icon={open ? 'caret-up' : 'caret-down'} />
                </div>
            </div>
            <AnimateHeight
                duration={300}
                height={open ? 'auto' : 0} // see props documentation below
            >
                <div
                    className={styles.accordionContent}
                    dangerouslySetInnerHTML={html ? { __html: html } : undefined}
                >
                    {text}
                </div>
            </AnimateHeight>
        </div>
    );
};

export default Accordion;
