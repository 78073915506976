import React, { Fragment, useState } from 'react';
import styles from './styles.module.scss';
import JobForm from '@components/JobForm/JobForm';

const TapTextCard = ({ title, content, jobApplication, opened }) => {
    return (
        <div
            // className={expand ? `${styles.container} ${styles.expanded}` : styles.container}
            className={`${styles.container} ${opened ? styles.expanded : ''} ${
                jobApplication ? styles.jobVacancy : ''
            }`}
        >
            <div className={styles.heading}>
                <div className={styles.headingText}>
                    <h3>{title}</h3>
                    <p className={styles.hint}>Tap to {opened ? 'shrink' : 'expand'}</p>
                </div>
                <img src="/images/squiggle.svg" alt="a squiggle line" />
            </div>
            <div className={`${styles.text} ${opened ? styles.notHidden : ''}`}>
                {!!content && <div dangerouslySetInnerHTML={{ __html: content }} />}
                <div className={styles.form}>{jobApplication && <JobForm />}</div>
            </div>
        </div>
    );
};

export default TapTextCard;
