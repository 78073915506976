import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Form from '@components/Form';

const JobForm = () => {
    const { form } = useStaticQuery(graphql`
        query {
            form: merlinForm(_id: { eq: "5fbf8a626d156800170c30e3" }) {
                _id
                name
                description
                meta {
                    updatedTimestamp
                }
                sections {
                    _id
                    title
                    description
                    multiple
                    min
                    max
                    fields {
                        label
                        hint
                        optional
                        options {
                            _id
                            label
                        }
                        validation {
                            type
                            condition
                            value
                            error
                        }
                        type
                        placeholder
                        id
                        _id
                    }
                }
            }
        }
    `);

    return <Form form={form} job />;
};

export default JobForm;
